import React from 'react';
import '../css/components/Header.css';
import logo from '../assets/logo.svg';

const Header = () => {
  return (
    <header className="header">
        
        <img src={logo} className="App-logo" alt="logo" />
    </header>
  );
};

export default Header;