import React from 'react';
import './/css/pages/HomePage.css';
import image1 from './/assets/a-1.webp';
import image2 from './/assets/a-2.webp';
import image3 from './/assets/a-3.webp';
import image4 from './/assets/b-1.webp';
import image5 from './/assets/b-2.webp';
import image6 from './/assets/b-3.webp';
import * as SolarIconSet from "solar-icon-set";
import { Helmet } from 'react-helmet';

const HomePage = () => {
  return (
    <div>
      <Helmet>
        <title>Meekiz - Find travel roomies, save on accommodation</title>
        <meta name="description" content="Meekiz, the platform that connects you with like-minded travelers to share hotel rooms or other accommodations, helping you save on your travel expenses." />
      </Helmet>
    <content>
      <section className="main-section">
        <div className="main-section-content">
          <h1>Find travel roomies, Save on accommodation</h1>
          <p>Welcome to Meekiz, the platform that connects you with travelers to share hotel rooms or other accommodations, helping you save on your travel expenses.</p>
          <a href="https://tally.so/r/mDeokZ"><button className="btn-primary">Get early access</button></a>
        </div>
      </section><section className="details-section">
          <h2>Share your accommodation, save money</h2>
          <p>Meekiz is the one and only platform for finding a co-traveler to share accommodation costs, making your travel experience more affordable and enjoyable. Connect with like-minded travelers and share a room, flat or house.</p>
          <div className="details">
            <div className="detail">
              <SolarIconSet.UsersGroupTwoRounded color="#3B6058" size={74} iconStyle="BoldDuotone" />
              <h3>Find your travel roomie</h3>
              <p>Browse through a diverse community of travelers and find the perfect roomie.</p>
            </div>
            <div className="detail">
              <SolarIconSet.ChatRoundMoney color="#3B6058" size={74} iconStyle="BoldDuotone" />
              <h3>Share costs</h3>
              <p>Save on accommodation expenses by sharing a room with like-minded travelers.</p>
            </div>
            <div className="detail">
              <SolarIconSet.SunFog color="#3B6058" size={74} iconStyle="BoldDuotone" />
              <h3>Enjoy your trip</h3>
              <p>Rest assured knowing that our platform prioritizes safety and security for all users. </p>
            </div>
          </div>
          <a href="https://tally.so/r/mDeokZ"><button className="btn-primary">Get notified of the launch</button></a>
        </section>
        <section className="image-overlay-section">
          <div className="images-container">
            <img src={image1} alt="Travel Image 1" className="image image1" />
            <img src={image2} alt="Travel Image 2" className="image image2" />
            <img src={image3} alt="Travel Image 3" className="image image3" />
          </div>
          <div className="text-content">
            <h2>Travel Smarter, Connect Deeper, Save More</h2>
            <p>Experience the joy of traveling with Meekiz. Connect with like-minded travelers, save money on accommodation, and create lasting memories. Our platform provides a safe and reliable way to find travel roomies.</p>
          </div>
        </section>
        <section className="details-section">
          <div className="details">
            <div className="detail">
              <span className="step">1</span>
              <h3>Create your profile</h3>
              <p>Sign up and create a profile on Meekiz to start connecting with potential travel roomies.</p>
            </div>
            <div className="detail">
              <span className="step">2</span>
              <h3>Search for travel roomies</h3>
              <p>Browse through a wide range of travelers and find the perfect match for your next trip.</p>
            </div>
            <div className="detail">
              <span className="step">3</span>
              <h3>Connect and Save</h3>
              <p>Connect with people before electing them as your roomie and start saving on accommodation costs.</p>
            </div>
          </div>
          <a href="https://tally.so/r/mDeokZ"><button className="btn-primary">Be the first to join the community</button></a>
        </section>
        <section className="image-overlay-section reverse">
          <div className="text-content">
            <h2>Created by travelers, for travelers</h2>
            <p>Meekiz is the result of years of solo traveling by our two co-founders. Finding the right balance between budget and comfort as always been part of their journeys. </p>
            <br></br><p>With Meekiz, they want to offer a safe, more economic way to travel the world, meeting incredible humans on the way.</p>
          </div>
          <div className="images-container">
            <img src={image4} alt="Travel Image 1" className="image image4" />
            <img src={image5} alt="Travel Image 2" className="image image5" />
            <img src={image6} alt="Travel Image 3" className="image image6" />
          </div>
        </section>
      </content>
      </div>
 );
};

export default HomePage;