import React from 'react';
import Header from './components/Header.js';
import HomePage from './HomePage.js';
import Footer from './components/Footer.js';
import Analytics from './components/Analytics.js';
import './css/App.css';


function App() {
  return (
    <div className="App">
      <Header />
      <Analytics />
      <HomePage />
      <Footer />
    </div>
  );
}

export default App;